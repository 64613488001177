.wrapper {
  display: grid;
  grid-template-columns: 480px 1fr;
  gap: 15px;
  height: 78vh;
}

.wrapper > div {
  overflow-y: scroll;
}

.wrapper > div h2 {
  display: flex;
}

.wrapper > div h2 span {
  margin-right: 5px;
}

.wrapper > div:nth-child(2) {
  background-color: white;
  padding: 15px;
}

.wrapper div:nth-child(2) h2:first-child {
  margin-top: 0;
}

.filterGroup {
  margin-bottom: 30px;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  border-left: 5px solid;
}

.filterGroup h3 {
  display: flex;
  margin-top: 0;
}

.filterGroup h3 span {
  margin-right: 5px;
}

.yellow {
  border-color: #ccb87b6e;
}

.purple {
  border-color: #5f5f8f42;
}

.videoWrapper {
  display: flex;
  gap: 30px;
}

.videoWrapper ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: small;
  margin: 0;
  padding: 0;
}

.videoWrapper ul li {
  display: block;
}

.videoWrapper ul li label {
  font-size: medium;
  display: block;
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}

.videoWrapper ul li label span {
  font-size: 13px;
  text-decoration: none;
  margin-right: 5px;
}
