.hfma-container {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
}

.hfma-header {
  background-color: #002e6d;
  color: white;
}

.hfma-body {
  background-color: white;
}

.filters-section {
  margin: 15px 0;
}

.filters-section .filter {
  margin-bottom: 10px;
}

.filters-section .filter label {
  display: block;
  font-size: small;
  font-weight: bold;
  margin-bottom: 5px;
}

.selection-section {
  margin: 15px 0;
}

.insights-section {
  background-color: lightgray;
  padding: 30px 60px;
}

.insights-section h3 {
  margin-top: 0;
}

.insights-section-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.insights-section-grid * {
  margin: 0;
}

.insights-section-grid .p-card {
  padding: 15px;
}

.insights-section-grid .p-card h3 {
  font-size: 1.3em;
  display: flex;
  gap: 5px;
  align-items: center;
  color: black;
  margin-bottom: 5px;
}

.insights-section-grid .p-card p {
  font-size: small;
}

.insights-section-grid .p-card .price {
  font-size: 1.1em;
  display: block;
  margin-bottom: 8px;
}

.p-card.average-rate {
  background-color: #e0f2ff; /* Light Blue */
}

.p-card.average-rate svg {
  color: #6190f4;
}

.p-card.lowest-rate {
  background-color: #e7f8e7; /* Light Green */
}

.p-card.lowest-rate svg {
  color: #6cd489;
}

.p-card.highest-rate {
  background-color: #ffe7e7; /* Light Red */
}

.p-card.highest-rate svg {
  color: #f6383c;
}

.p-card.closest-competitor {
  background-color: #f4e7ff; /* Light Purple */
}

.p-card.closest-competitor svg {
  color: #bf89f6;
}

.price {
  font-weight: bold;
  color: black;
}

.hc-section {
  text-align: center;
  padding: 45px 30px;
  color: white;
  background-color: #002e6d;
  margin: 15px 0 45px 0;
}
