.p-component {
  font-family: "Calibri", sans-serif;
}
.p-button {
  background-color: #315a79;
  border-color: #315a79;
}

.p-selectbutton .p-button.p-highlight {
  background-color: #315a79;
  border-color: #315a79;
}

.p-selectbutton .p-button.p-highlight:hover {
  background-color: #547a97;
  border-color: #547a97;
}

.p-progressbar .p-progressbar-value {
  background-color: var(--primary-color);
}

.p-button.p-button-outlined {
  color: #315a79;
  border-color: #315a79;
}
.p-button:enabled:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.p-button.p-button-outlined:enabled:hover {
  color: #315a79;
  border-color: #315a79;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  font-size: small;
  padding: 10px 30px;
}

input.p-inputtext.p-component {
  min-width: 440px;
}

.p-chips-input-token input {
  min-width: auto !important;
}

.p-chips-token.p-highlight {
  margin: 5px 0px;
}

.filter-group .p-component {
  width: 100%;
}

.data-room-landing .filter-item {
  max-width: 340px;
}

.data-room .filters .p-component {
  width: 100%;
}

.data-room .filters .p-component .p-splitbutton-menubutton {
  width: 42px;
}

.reset-btn {
  margin: 10px 0;
  background-color: lightgray;
  align-content: center;
  border: none;
  color: black;
}

.reset-btn:enabled:hover {
  background-color: rgb(202, 202, 202);
  align-content: center;
  border: none;
  color: black;
}

.highcharts-datagrid-cell[data-data-type="number"] {
  justify-content: flex-end;
}
