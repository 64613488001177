@font-face {
  font-family: "Fira Sans";
  src: url("../fonts/calibri-font-family/calibri-regular.ttf"),
    url("../fonts/calibri-font-family/calibri-italic.ttf"),
    url("../fonts/calibri-font-family/calibri-bold-italic.ttf"),
    url("../fonts/calibri-font-family/calibri-bold.ttf");
}

:root {
  --branded-yellow: #ffbe00;
  --highcharts-background-color: white;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #f3f3f3;
  font-family: "Calibri", sans-serif;
  color: black;
}

* {
  box-sizing: border-box;
}

.theme-wrapper {
  display: grid;
  gap: 15px;
  height: 100vh;
  grid-template-columns: 0 auto;
  grid-template-rows: 45px auto 30px;
  grid-template-areas:
    "nav-main header"
    "nav-main body-main"
    "footer-main footer-main";
}

.theme-wrapper.expand {
  grid-template-columns: 280px auto;
}

header {
  grid-area: header;
}

.nav-main {
  grid-area: nav-main;
}

.body-main {
  grid-area: body-main;
  margin-right: 45px;
}

.footer-main {
  grid-area: footer-main;
  display: flex;
  align-items: center;
  padding-right: 15px;
  justify-content: flex-end;
  color: white;
  background-color: var(--primary-color);
  font-size: small;
}

.footer-main a {
  color: white;
}

textarea {
  min-width: 540px;
  min-height: 100px;
}

a {
  color: var(--primary-color);
  text-decoration: underline;
}

.box {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 15px;
}

.btn {
  display: inline-flex;
  padding: 5px;
  margin-bottom: 10px;
  background: lightblue;
}

.btn-secondary {
  background-color: var(--branded-secondary) !important;
  border-color: var(--branded-secondary) !important;
  color: black !important;
}

.btn-secondary.grey {
  background-color: lightgrey !important;
  border-color: rgb(195, 195, 195) !important;
}

.btn-secondary:hover {
  background-color: #e3b451 !important;
}
