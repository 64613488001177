.layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  overflow: hidden;
}

.layout h2 {
  display: flex;
  align-items: center;
}

.wrapper {
  display: grid;
  grid-template-columns: 380px 1fr;
  gap: 30px;
}

.plot {
  display: block;
  background-color: white;
}

.dataProduct {
  display: block;
  border-radius: 5px;
  background-color: white;
  border-left: 5px solid;
  height: 100%;
}

.dataProduct.yellow {
  border-color: #ccb87b6e;
}

.dataProduct.purple {
  border-color: #5f5f8f42;
}

.dataProduct h4 {
  padding: 15px;
  margin: 0;
}

.initFilters {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.initFilters .filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.initFilters .filter label {
  display: flex;
  font-size: small;
  align-items: center;
  margin-bottom: 8px;
  gap: 3px;
}

.labelIco {
  font-size: 1.4em;
  opacity: 0.5;
}

.initFilters .filter {
  display: flex;
}

.subFiltersWrapper {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.subFilters {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  gap: 15px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 5px;
}

.subFilters label {
  display: flex;
  font-size: small;
  margin-bottom: 5px;
}

.subFilters input {
  max-width: 300px;
}
