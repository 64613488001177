/* @import url("https://code.highcharts.com/css/highcharts.css"); */
@import url("https://code.highcharts.com/datagrid/css/datagrid.css");
@import url("https://code.highcharts.com/dashboards/css/dashboards.css");

.highcharts-plot-line {
  stroke: #e3b451;
  stroke-width: 2px;
}
:root,
.highcharts-plot-line-label {
  fill: var(--highcharts-color-99);
}
/* :root .highcharts-light {
  --highcharts-color-0: #005c9e;
  --highcharts-color-1: #9e8100;
  --highcharts-color-2: #009e8e;
  --highcharts-color-3: #9e1d00;
  --highcharts-color-4: #1a3f59;
  --highcharts-color-5: #c7a200;
  --highcharts-color-6: #005950;
  --highcharts-color-7: #591000;
  --highcharts-color-99: #eb2b00;
} */

/* mma */

/* #FF8C00
#EE3D8B
#8246AF
#F0F0F0
#00968F */

:root .highcharts-light {
  --highcharts-color-0: #ff8c00;
  --highcharts-color-1: #ee3d8b;
  --highcharts-color-2: #8246af;
  --highcharts-color-3: #00968f;
  --highcharts-color-4: #bd0026;

  --highcharts-color-5: #c7a200;
  --highcharts-color-6: #005950;
  --highcharts-color-7: #591000;
  --highcharts-color-99: #f03b20;
}

h1#title {
  padding-top: 10px;
  margin: 0;
  background-color: #3d3d3d;
  text-align: center;
  color: white;
}

.highcharts-column-series .highcharts-color-0 {
  fill: var(--highcharts-color-0);
}

.highcharts-color-0 {
  stroke: var(--highcharts-color-0);
}

.highcharts-dashboards-wrapper {
  background-color: unset;
}
